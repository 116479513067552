// import Img1 from './assets/1.jpg';

export default [
  {
    // img: Img1,
    // title: 'Love Story',
    description: `In the realm of high school memories, our paths converged, only to drift apart from 2013 until the magical December of 2020. Fate intervened as my now-boyfriend initiated contact on Instagram, weaving his presence into my daily stories. Providentially, the timing aligned with the end of my previous relationship, opening the door for me to reciprocate his messages.
    <br /><br />
    For seven months, WhatsApp became the canvas where our connection painted a portrait of shared laughter, dreams, and growing affection. The digital realm bridged the gap between Jakarta and Toronto, transforming our relationship from pixels to palpable emotions. The first declaration of love echoed through a video call, marking a milestone in our lives.
    <br /><br />
    From the very beginning, there was something about him that allowed me to be my true self, fostering a comfort that transcended the virtual space. As our love story unfolded, reaching its one-year milestone, the decision to journey into marriage became the natural progression. And so, amid the distances of Jakarta and Toronto, our love triumphed, creating a tapestry of connection and commitment that would endure beyond the pixels and through the miles.`,
  },
];
